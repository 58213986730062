'use client'

import { useSearchParams } from 'next/navigation'
import { createContext, FunctionComponent, ReactNode, useCallback, useRef, useState } from 'react'

export type AlertNotificationContextType = {
  onCloseNotification: () => void

  addNotification: (message: string) => void
  message: string | null
}

export const AlertNotificationContext = createContext<AlertNotificationContextType>({
  onCloseNotification: () => {},
  addNotification: () => {},
  message: null,
})

const AlertNotificationProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const searchParams = useSearchParams()
  const [message, setMessage] = useState<string | null>(searchParams?.get('notification') || null)
  const timeOutRef = useRef<NodeJS.Timeout>()

  const onCloseNotification = useCallback(() => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current)
    }
    setMessage(null)
  }, [])

  const addNotification = useCallback((newMessage: string) => {
    setMessage(newMessage)

    timeOutRef.current = setTimeout(() => {
      setMessage(null)
    }, 10000)
  }, [])

  return (
    <AlertNotificationContext.Provider value={{ addNotification, onCloseNotification, message }}>
      {children}
    </AlertNotificationContext.Provider>
  )
}

export default AlertNotificationProvider
