'use client'
import { StoreConfig } from '../../.mesh'
import { initGTM } from '../../src/presentation/utils/gtmHelpers'

export function Gtm(storeConfig: StoreConfig) {
  const gtmTrackingId = storeConfig.google_gtm_account ?? process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS
  initGTM(gtmTrackingId)

  return null
}
